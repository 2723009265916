import { CheckCircle2 } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardFooter } from "../components/ui/card";

export function EmailVerification() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const handleGoToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <Card className="w-[380px] p-6">
        <CardContent className="space-y-4 text-center pt-6">
          <div className="flex justify-center">
            <CheckCircle2 className="h-12 w-12 text-green-500" />
          </div>
          
          <h2 className="text-2xl font-semibold tracking-tight">
            {status === "true" ? (
              "Email Successfully Verified!"
            ) : (
              "Email Already Verified"
            )}
          </h2>
          
          <p className="text-sm text-muted-foreground">
            {status === "true" 
              ? "Thank you for verifying your email address. You can now proceed to login."
              : "Your email has already been verified. You can proceed to login."
            }
          </p>
        </CardContent>
        
        <CardFooter>
          <Button 
            className="w-full" 
            onClick={handleGoToLogin}
          >
            Go to Login
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
} 