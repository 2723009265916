import { createContext, useContext, useEffect, useState } from 'react';
import { authService } from '../services/api.service';
import { tokenService } from '../services/token.service';
import { userService } from '../services/user.service';
import { SimpleDecodedToken } from '../types/auth.types';

interface UserContextType {
    user: SimpleDecodedToken | null;
    setUser: (user: SimpleDecodedToken | null) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = useState<SimpleDecodedToken | null>(() => {
        // Try to get user data from localStorage on initial load
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });

    useEffect(() => {
        //verify if your token is valid so we can refersh
        if (user?.expirationTime) {
            let time = user?.expirationTime * 1000;

            if (time < Date.now()) {
                console.log("Expired, refreshing now...");
                let refreshToken = localStorage.getItem("refreshToken");

                if (refreshToken) {
                    authService.refreshToken(refreshToken).then((response: any) => {
                        tokenService.setTokens({
                            token: response.data.token,
                            refreshToken: response.data.refreshToken
                        });

                        const decodedToken = userService.decodeToken(response.data.token);
                        const simplifiedToken = userService.simplifyDecodedToken(decodedToken);
                        setUser(simplifiedToken);
                    });
                }
            }
        }

        //verify if you have subscription
        const subscriptionSimplified = user?.subscription ? JSON.parse(user?.subscription) : null;
        if (subscriptionSimplified) {
            if (subscriptionSimplified.IsActive.toLowerCase() == "false") {
                debugger
                console.log("Subscription expired!");
            }
        }

        // When user changes, save to localStorage if it exists, remove if null
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            // Check if we have a token but no user data
            const currentUser = userService.getCurrentUser();
            if (currentUser) {
                setUser(currentUser);
            } else {
                localStorage.removeItem('user');
            }
        }
    }, [user]);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}