import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import DashboardLogo from "../assets/voltox-dashboard-logo.png";
import { UserProfileDropdown } from '../components/UserProfileDropdown';
import { api } from '../services/api.service';

interface Subscription {
    name: string;
    subscriptionType: string;
    validUntil: string;
    hasActiveSubscription: boolean;
}

interface IFormInput {
    id: number | undefined;
    name: string;
    companyName: string;
    email: string;
    frSubscription: Subscription;
    ageSubscription: Subscription;
}

export const Admin = () => {
    const { register, handleSubmit, reset } = useForm<IFormInput>();
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({}); // To track validation errors
    const [editingUser, setEditingUser] = useState<IFormInput | undefined>();

    const onSubmit: SubmitHandler<IFormInput> = async data => {
        const errors: { [key: string]: string } = {};

        if (!data.name || data.name.trim() === "") {
            errors.name = "Name is required.";
        }

        if (!data.companyName || data.companyName.trim() === "") {
            errors.companyName = "Company Name is required.";
        }

        if (!data.email || data.email.trim() === "") {
            errors.email = "Email is required.";
        }

        if (!data.frSubscription.subscriptionType || data.frSubscription.subscriptionType.trim() === "") {
            errors.subscriptionType = "Subscription Type is required.";
        }

        if (!data.ageSubscription.subscriptionType || data.ageSubscription.subscriptionType.trim() === "") {
            errors.subscriptionType = "Subscription Type is required.";
        }

        if (!data.frSubscription.validUntil || data.frSubscription.validUntil.trim() === "") {
            errors.validUntil = "Date is required.";
        }

        if (!data.ageSubscription.validUntil || data.ageSubscription.validUntil.trim() === "") {
            errors.validUntil = "Date is required.";
        }

        if (Object.keys(errors).length > 0) {
            setFieldErrors(errors);
            return; // Do not proceed if there are validation errors
        }

        setFieldErrors({}); // Clear errors if validation passes

        const response = await api.post("/api/User/EditUser", data) as any;

        if (!response?.flag) {
            toast.success(response.message);
            throw new Error(response.message);
        }

        toast.success("Successfully edited!");
        fetchData();
        cancelEdit();
    }

    const fetchData = async () => {
        try {
            const response = await api.get("/api/User/GetUsers") as any;
            if (!response?.flag) {
                throw new Error(response?.message);
            }

            setError(null);
            setUsers(response?.data); // Assuming `response.data.users` contains the users
        } catch (error: any) {
            setError(error.message);
        }
    };

    const edit = (user: IFormInput) => {
        if (user.frSubscription.validUntil) {
            const datePart = user.frSubscription.validUntil.split("T")[0];
            user.frSubscription.validUntil = datePart;
        }

        if (user.ageSubscription.validUntil) {
            const datePart = user.ageSubscription.validUntil.split("T")[0];
            user.ageSubscription.validUntil = datePart;
        }

        setEditingUser(user);
        setShowEdit(true);
        reset(user);
    }

    const cancelEdit = () => {
        setEditingUser(undefined);
        setShowEdit(false);
        reset();
    }

    const deleteUser = async (id: number) => {
        if (window.confirm("Are you sure?") == true) {
            const response = await api.post("/api/User/DeleteUser", id) as any;

            if (!response?.flag) {
                toast.success(response.message);
                throw new Error(response.message);
            }

            toast.success("Successfully deleted!");
            fetchData();
        }
    }

    const suspendSubscription = async (name: string) => {
        if (name == "FaceRecognition") {
            const response = await api.post("/api/User/SuspendSubscription", { userId: editingUser?.id, name: name }) as any;

            if (!response?.flag) {
                toast.success(response.message);
                throw new Error(response.message);
            }

            toast.success("Successfully " + (editingUser?.frSubscription.hasActiveSubscription ? "suspended" : "activated") + " subscription!");
            if (editingUser) {
                setEditingUser({
                    ...editingUser,
                    frSubscription: {
                        ...editingUser.frSubscription,
                        hasActiveSubscription: !editingUser.frSubscription.hasActiveSubscription
                    },
                    ageSubscription: {
                        ...editingUser.ageSubscription,
                        hasActiveSubscription: !editingUser.ageSubscription.hasActiveSubscription
                    }
                });
            }
        }
        else if (name == "AgeVerification") {
        }
        fetchData();
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <nav className="bg-white">
                <div className="d-flex h-16">
                    <div className="d-flex justify-content-between align-items-center" style={{ width: "95%", margin: "auto" }}>
                        <img src={DashboardLogo} alt="logo" width={150} />
                        <h1 className='font-bold tracking-tight' style={{ fontSize: "18px", color: "#1b5257" }}>Admin Dashboard</h1>
                        <UserProfileDropdown />
                    </div>
                </div>
            </nav>
            <div style={{ backgroundColor: "#f5f6fa", height: "100vh" }}>
                <div className="container pt-5">
                    <div className="row">
                        <h2 className="text-3xl font-bold tracking-tight">Client Management</h2>
                        {showEdit &&
                            <div className='col-md-12 mt-5'>
                                <form onSubmit={handleSubmit(onSubmit)} className="">
                                    <div className="row gy-3">
                                        <div className="col-md-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input type='text' id='name'
                                                className={fieldErrors.name ? 'form-control is-invalid' : 'form-control'}
                                                {...register("name")}
                                            />
                                            {fieldErrors.name && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.name}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="companyName" className="form-label">Company Name</label>
                                            <input type='text' id='companyName' className={fieldErrors.companyName ? 'form-control is-invalid' : 'form-control'} {...register("companyName")} />
                                            {fieldErrors.companyName && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.companyName}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type='text' id='email' className={fieldErrors.email ? 'form-control is-invalid' : 'form-control'} {...register("email")} />
                                            {fieldErrors.email && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.email}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-3">
                                            <label htmlFor="frSubscriptionType" className="form-label">Face Recognition Subscription Type</label>
                                            <select
                                                id="frSubscriptionType"
                                                className={fieldErrors.frSubscriptionType ? 'form-select is-invalid' : 'form-select'}
                                                {...register("frSubscription.subscriptionType")}
                                            >
                                                <option value="">Select a plan</option>
                                                <option value="Demo">Demo</option>
                                                <option value="Go">Go</option>
                                                <option value="Boost">Boost</option>
                                                <option value="Premium">Premium</option>
                                            </select>
                                            {fieldErrors.frSubscriptionType && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.frSubscriptionType}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="frValidUntil" className="form-label">Face Recognition Valid Until</label>
                                            <input type='date' id='frValidUntil' className={fieldErrors.frValidUntil ? 'form-control is-invalid' : 'form-control'} {...register("frSubscription.validUntil", { maxLength: 20 })} />
                                            {fieldErrors.frValidUntil && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.frValidUntil}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="ageSubscriptionType" className="form-label">Age Verification Subscription Type</label>
                                            <select
                                                id="ageSubscriptionType"
                                                className={fieldErrors.ageSubscriptionType ? 'form-select is-invalid' : 'form-select'}
                                                {...register("ageSubscription.subscriptionType")}
                                            >
                                                <option value="">Select a plan</option>
                                                <option value="Demo">Demo</option>
                                                <option value="Go">Go</option>
                                                <option value="Boost">Boost</option>
                                                <option value="Premium">Premium</option>
                                            </select>
                                            {fieldErrors.ageSubscriptionType && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.ageSubscriptionType}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="ageValidUntil" className="form-label">Age Verification Valid Until</label>
                                            <input type='date' id='ageValidUntil' className={fieldErrors.ageValidUntil ? 'form-control is-invalid' : 'form-control'} {...register("ageSubscription.validUntil", { maxLength: 20 })} />
                                            {fieldErrors.ageValidUntil && (
                                                <div className="invalid-feedback">
                                                    {fieldErrors.ageValidUntil}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-10 mt-4 d-flex align-items-end">
                                        <button type='button' onClick={() => suspendSubscription("FaceRecognition")} className={editingUser?.frSubscription.hasActiveSubscription ? 'btn btn-warning' : 'btn btn-info'}>{editingUser?.frSubscription.hasActiveSubscription ? 'Suspend ' : 'Activate '} Face Recognition Subscription</button>
                                        <button type='button' onClick={() => suspendSubscription("AgeVerification")} className={editingUser?.ageSubscription.hasActiveSubscription ? 'btn ml-3 btn-warning' : 'btn ml-3 btn-info'}>{editingUser?.ageSubscription.hasActiveSubscription ? 'Suspend ' : 'Activate '} Age Verification Subscription</button>
                                        <button type='button' onClick={cancelEdit} className='btn btn-danger ml-3' style={{ width: "150px" }}>Cancel</button>
                                        <button type='submit' className='btn btn-success ml-3' style={{ width: "150px" }}>Save</button>
                                    </div>
                                </form>
                            </div>
                        }

                        <div className="table-responsive mt-5">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='p-4' scope="col">Name</th>
                                        <th className='p-4' scope="col">Company Name</th>
                                        <th className='p-4' scope="col">Email</th>
                                        <th className='p-4' scope="col">Subscription Plan</th>
                                        <th className='p-4' scope="col">FR Valid Until</th>
                                        <th className='p-4' scope="col">AGE Valid Until</th>
                                        <th className='p-4' scope="col">Register Date</th>
                                        <th className='p-4' scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {error != null && <tr className='text-center'><td colSpan={12}>{error}</td></tr>}
                                    {users.map((x: any) => {
                                        return (
                                            <tr key={x.id}>
                                                <td className="p-4">{x.name}</td>
                                                <td className="p-4">{x.companyName}</td>
                                                <td className="p-4">{x.email}</td>
                                                <td className="p-4">
                                                    {
                                                        x.frSubscription?.name + " - " + x.frSubscription?.subscriptionType
                                                    }
                                                    <br />
                                                    {
                                                        x.ageSubscription?.name + " - " + x.ageSubscription?.subscriptionType
                                                    }
                                                </td>
                                                <td className="p-4">
                                                    {
                                                        x.frSubscription ? new Date(x.frSubscription.validUntil.split("T")[0]).toLocaleDateString("de-DE") : "Not Purchased"
                                                    }
                                                </td>
                                                <td className="p-4">
                                                    {
                                                        x.ageSubscription ? new Date(x.ageSubscription.validUntil.split("T")[0]).toLocaleDateString("de-DE") : "Not Purchased"
                                                    }
                                                </td>
                                                <td className="p-4">{new Date(x.dateRegistered.split("T")[0]).toLocaleDateString("de-DE")}</td>
                                                <td className='p-4'>
                                                    <button onClick={() => edit(x)}><i style={{ color: "green", fontSize: "20px", cursor: "pointer" }} className="bi bi-pencil-square pr-5"></i></button>
                                                    <button onClick={() => deleteUser(x.id)}><i style={{ color: "red", fontSize: "20px", cursor: "pointer" }} className="bi bi-trash3"></i></button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}; 