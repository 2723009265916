
export const Settings = () => {
  return (
    <div className="space-y-4 fade-in">
    <div className="flex items-center justify-between">
        <h2 className="text-3xl font-medium tracking-tight text-[#202224] font-['Nunito_Sans']">
          Settings
        </h2>
      </div>
    </div>
  );
}; 