import { clsx, type ClassValue } from "clsx";
import { format } from "date-fns";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const formatDateTime = (date: string | Date) => {
  if (!date) return '';
  
  try {
    const d = new Date(date);
    
    // Check if date is valid
    if (isNaN(d.getTime())) {
      return '';
    }

    // Format in local timezone with 24-hour format
    return format(d, "dd/MM/yyyy HH:mm");
  } catch (error) {
    console.error("Error formatting date:", error);
    return '';
  }
};
