import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastProvider } from './components/providers/ToastProvider';
import { UserProvider } from './contexts/UserContext';
import { Dashboard } from './pages/Dashboard';
import { EmailVerification } from './pages/EmailVerification';
import { ForgotPassword } from './pages/ForgotPassword';
import { Login } from './pages/Login';
import ResendConfirmEmail from './pages/ResendConfirmEmail';
import ResetPassword from './pages/ResetPassword';
import { Signup } from './pages/Signup';

// Private Route component for the Dashboard
const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
    const token = localStorage.getItem('token');
    return token ? <>{children}</> : <Navigate to="/login" />;
};

// Public Route for unauthenticated users (Login, Signup, etc.)
const PublicRoute = ({ children }: { children: React.ReactNode }) => {
    const token = localStorage.getItem('token');
    return !token ? <>{children}</> : <Navigate to="/dashboard" />;
};

const queryClient = new QueryClient();

function App() {
    return (
        <UserProvider>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <Routes>
                        {/* Public Routes - can be accessed by anyone */}
                        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
                        <Route path="/signup" element={<PublicRoute><Signup /></PublicRoute>} />
                        <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
                        <Route path="/resendConfirmationEmail" element={<PublicRoute><ResendConfirmEmail /></PublicRoute>} />
                        <Route path="/resetPassword" element={<PublicRoute><ResetPassword /></PublicRoute>} />
                        <Route path="/emailVerification" element={<PublicRoute><EmailVerification /></PublicRoute>} />

                        {/* Private Route - only accessible if user is logged in */}
                        <Route
                            path="/dashboard"
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        />

                        {/* Redirect default path to dashboard */}
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                    </Routes>
                </Router>
                <ToastProvider />
            </QueryClientProvider>
        </UserProvider>
    );
}

export default App;
