import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import LoginBackground from '../assets/login-background.png';
import { SocialAuth } from '../components/auth/SocialAuth';
import { AuthLayout } from '../components/layouts/AuthLayout';
import { Button } from '../components/ui/button';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../components/ui/form';
import { Input } from '../components/ui/input';
import Spinner from '../components/ui/spinner';
import { useUser } from '../contexts/UserContext';
import "../css/login.css";
import { authService } from '../services/api.service';
import { tokenService } from '../services/token.service';
import { userService } from '../services/user.service';
import { LoginResponse } from '../types/auth.types';

const loginSchema = z.object({
    email: z.string().email('Invalid email address'),
    password: z.string().min(3, 'Password must be at least 6 characters'),
});

export type LoginFormValues = z.infer<typeof loginSchema>;

const loginUser = async (data: LoginFormValues): Promise<LoginResponse> => {
    const response = await authService.login(data);
    if (!response.data.flag) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const Login = () => {
    const navigate = useNavigate();
    const { setUser } = useUser();

    const form = useForm<LoginFormValues>({
        resolver: zodResolver(loginSchema),
        defaultValues: {
            email: '',
            password: '',
        },
        mode: 'onSubmit',
    });

    const mutation = useMutation<LoginResponse, Error, LoginFormValues>({
        mutationFn: loginUser,
        onSuccess: (response) => {
            if (response.flag) {
                tokenService.setTokens({
                    token: response.tokens.token,
                    refreshToken: response.tokens.refreshToken
                });

                try {
                    const decodedToken = userService.decodeToken(response.tokens.token);
                    const simplifiedToken = userService.simplifyDecodedToken(decodedToken);
                    setUser(simplifiedToken);
                    toast.success(response.message);
                    navigate('/dashboard');
                } catch (error) {
                    console.error('Error decoding token:', error);
                    toast.error('Error processing login');
                }
            } else {
                toast.error(response.message);
            }
        },
        onError: (error: any) => {
            const errorMessage = error.response?.data?.message || error.message || 'Login failed';
            toast.error(errorMessage);
        },
    });

    const onSubmit = async (data: LoginFormValues) => {
        const result = await form.trigger();
        if (result) {
            mutation.mutate(data);
        }
    };

    return (
        <AuthLayout
            backgroundImage={LoginBackground}
            title="Welcome back!"
            subtitle="Enter your credentials to access your account"
        >
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="">
                    <div className="">
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Enter your email" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Password</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="password"
                                            placeholder="Enter your password"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="flex justify-start mt-4">
                        <Link
                            to="/forgot-password"
                            className="text-sm text-black hover:text-gray-900 hover:underline"
                        >
                            Forgot Password?
                        </Link>
                    </div>

                    <Button
                        type="submit"
                        className="w-full mt-4"
                        disabled={mutation.isPending}
                    >
                        {mutation.isPending ? (
                            <div className="flex items-center justify-center gap-2">
                                <Spinner />
                            </div>
                        ) : (
                            "Log In"
                        )}
                    </Button>
                    <SocialAuth />
                    <div className="text-center text-sm text-black font-medium mt-4">
                        Don't have an account?{' '}
                        <Link
                            to="/signup"
                            className="font-normal text-black hover:text-gray-900 hover:underline"
                        >
                            Sign Up
                        </Link>
                    </div>
                </form>
            </Form>
        </AuthLayout>
    );
}; 