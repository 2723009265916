import React from 'react';
import { userService } from '../../services/user.service';

interface VoltoxDemoButtonProps {
  className?: string;
}

export const VoltoxDemoButton: React.FC<VoltoxDemoButtonProps> = ({ className = '' }) => {
  const handleRedirect = () => {
    const user = userService.getCurrentUser();
    if (user?.clientId) {
      window.open(
        `https://demo.voltox.ai/landing.html?client_id=${user.clientId}`,
        '_blank',
        'noopener,noreferrer'
      );
    } else {
      console.error('No client ID available');
    }
  };

  return (
    <button
      onClick={handleRedirect}
      className={`
        flex items-center justify-center
        px-6 py-2.5
        text-sm font-medium text-white
        bg-gradient-to-r from-[#000000] via-[#041112] to-[#1C5257]
        rounded-lg
        transition-all duration-200
        hover:opacity-90
        outline-none
        ${className}
      `}
    >
      Voltox Demo
    </button>
  );
}; 