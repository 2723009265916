import AppleIcon from '../../icons/AppleIcon';
import GoogleIcon from '../../icons/GoogleIcon';
import VoltoxIcon from '../../icons/VoltoxIcon';
import SocialButton from '../SocialButton';
import { Separator } from '../ui/separator';

export const SocialAuth = () => {
    return (
        <>
            <div className="relative my-3">
                <div className="absolute inset-0 flex items-center">
                    <Separator className="w-full" />
                </div>
                <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-black">Or</span>
                </div>
            </div>

            <div className="grid grid-cols-3 gap-4">
                <SocialButton icon={<GoogleIcon />} label="Sign in with Google" />
                <SocialButton icon={<AppleIcon />} label="Sign in with Apple" />
                <SocialButton icon={<VoltoxIcon />} label="Smile ID" />
            </div>
        </>
    );
}; 