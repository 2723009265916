import { DashboardOverview } from "../components/dashboard-sections/DashboardOverview";
import { FaceRecognition } from "../components/dashboard-sections/FaceRecognition";
import { Settings as SettingsComponent } from "../components/dashboard-sections/Settings";
import { VerificationHistory } from "../components/dashboard-sections/VerificationHistory";
import AgeVerification from "../icons/AgeVerification";
import DashboardIcon from "../icons/DashboardIcon";
import FaceRecognitionIcon from "../icons/FaceRecognitionIcon";
import SettingsIcon from "../icons/SettingsIcon";

export const routes = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        icon: DashboardIcon,
        component: DashboardOverview,
    },
    {
        id: 'age-verification',
        title: 'Age Verification',
        icon: AgeVerification,
        component: VerificationHistory,
    },
    {
        id: 'face-recognition',
        title: 'Face Recognition',
        icon: FaceRecognitionIcon,
        component: FaceRecognition,
    },
    {
        id: 'settings',
        title: 'Settings',
        icon: SettingsIcon,
        component: SettingsComponent,
    },
] as const;

export type RouteId = typeof routes[number]['id']; 