import { Card, CardContent } from "../ui/card";

export const FaceRecognition = () => {
  return (
    <div className="space-y-4 fade-in">
    <div className="flex items-center justify-between">
        <h2 className="text-3xl font-medium tracking-tight text-[#202224] font-['Nunito_Sans']">
          Face Recognition History
        </h2>
      </div>

      <Card>
        <CardContent className="p-0 border rounded-lg shadow-none">
            <div className="text-center text-gray-500 p-4">No recognition history found</div>
        </CardContent>
      </Card>
    </div>
  );
}; 