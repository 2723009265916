import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import SignupBackground from '../assets/login-background.png';
import { SocialAuth } from '../components/auth/SocialAuth';
import { AuthLayout } from '../components/layouts/AuthLayout';
import { Button } from '../components/ui/button';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../components/ui/form';
import { Input } from '../components/ui/input';
import Spinner from '../components/ui/spinner';
import { authService } from '../services/api.service';

const signupSchema = z.object({
    name: z.string().min(2, 'Name must be at least 2 characters'),
    companyName: z.string().min(2, 'Company name must be at least 2 characters'),
    email: z.string().email('Invalid email address'),
    password: z.string().min(6, 'Password must be at least 6 characters'),
    confirmPassword: z.string().min(1, 'Confirm password is required')
}).refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
});

type SignupFormValues = z.infer<typeof signupSchema>;

const signupUser = async (data: SignupFormValues) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    const response = await authService.signup(formData);
    return response.data;
};

export const Signup = () => {
    const navigate = useNavigate();

    const form = useForm<SignupFormValues>({
        resolver: zodResolver(signupSchema),
        defaultValues: {
            name: '',
            companyName: '',
            email: '',
            password: '',
            confirmPassword: '',
        },
        mode: 'onSubmit',
    });

    const mutation = useMutation({
        mutationFn: signupUser,
        onSuccess: (response) => {
            if (response.flag) {
                toast.success(response.message);
                navigate('/login');
            } else {
                toast.error(response.message);
            }
        },
        onError: (error: any) => {
            toast.error(error.response?.data?.message || 'Registration failed');
        },
    });

    const onSubmit = async (data: SignupFormValues) => {
        const result = await form.trigger();
        if (result) {
            mutation.mutate(data);
        }
    };

    return (
        <AuthLayout
            backgroundImage={SignupBackground}
            title="Get Started Now"
        >
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="">
                    <div className="">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Enter your full name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="companyName"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Company Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Enter your company name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Enter your email" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Password</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="password"
                                            placeholder="Enter your password"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="confirmPassword"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Confirm Password</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="password"
                                            placeholder="Confirm your password"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <Button
                        type="submit"
                        className="w-full mt-4"
                        disabled={mutation.isPending}
                    >
                        {mutation.isPending ? (
                            <div className="flex items-center justify-center gap-2">
                                <Spinner />
                            </div>
                        ) : (
                            "Sign up"
                        )}
                    </Button>
                    <SocialAuth />
                    <div className="text-center text-sm text-black font-medium mt-4">
                        Already have an account?{' '}
                        <Link
                            to="/login"
                            className="font-normal text-black hover:text-gray-900 hover:underline"
                        >
                            Sign in
                        </Link>
                    </div>
                </form>
            </Form>
        </AuthLayout>
    );
}; 